export default [{
  title: 'Dashboard',
  route: 'dashBoard',
  icon: 'table-cells-large',
}, {
  title: 'Cari Kartlar',
  route: 'cariListe',
  icon: 'list',
}, {
  title: 'İşlemler',
  icon: 'arrow-pointer',
  children: [{
    title: 'Gelir Kaydı',
    icon: 'plus',
  }, {
    title: 'Gider Kaydı',
    icon: 'minus',
  }, {
    title: 'Vardiya Kapat',
    icon: 'circle-stop',
  }],
}, {
  title: 'Raporlar',
  icon: 'table-list',
  children: [{
    title: 'Vardiya Detayı',
    icon: 'chart-line',
    route: {
      name: 'vardiyaDetayi',
      params: { id: 0 },
    },
  }, {
    title: 'Vardiya Listesi',
    icon: 'bars',
    route: 'vardiyaListesi',
  }, {
    title: 'Kasa Raporu',
    route: 'kasaRaporu',
    icon: 'box-archive',
  }],
}, {
  title: 'Tanımlamalar',
  icon: 'clone',
  children: [{
    title: 'Masa Salon',
    route: 'salon&masa',
    icon: 'list',
  }, {
    title: 'Menu',
    route: 'menu',
    icon: 'utensils',
  }],
},
  {
    title: 'Ayarlar',
    icon: 'gear',
    children: [{
      title: 'Departman Yönetimi',
      route: 'departman',
      icon: 'database',
    }, {
      title: 'Kullanıcı Yönetimi',
      route: 'kullanicilar',
      icon: 'users',
    }],
  },
  {
    title: 'Mutfak',
    route: 'mutfak',
    icon: '',
  }]
