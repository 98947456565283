<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none responsive-control">
      <b-nav-item to="/">
        <b-img
            class="logo-css"
            :src="oysLogo"
            fluid
            alt="Oys Panel"
        />
      </b-nav-item>
    </ul>
    <ul class="nav navbar-nav d-xl-none responsive-control">
      <li class="nav-item">
        <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
        >
          <feather-icon
              icon="MenuIcon"
              size="21"
              class="text-white"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <bookmarks/>
    </div>
    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block"/>
      <!--      <cart-dropdown/>-->
      <!--      <notification-dropdown/>-->
      <user-dropdown/>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItem, BImg,
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  data() {
    return {
      oysLogo: require('@/assets/images/restoran/logo/logo.png'),
    }
  },
  components: {
    BImg,
    BNavItem,
    BLink,
    // Navbar Components
    BNavbarNav,
    Bookmarks,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },

}
</script>

<style>
@media (min-width: 992px) {
  .responsive-control {
    display: none;
  }
}

.logo-css {
  height: 40px !important;
}
</style>
