<template>
  <b-modal
      id="gelir-kaydi"
      centered
      modal-class="modal-success"
      no-fade
      hide-footer
      title="Gelir Kaydı"
  >
    <b-row>
      <b-col lg="6" md="6" sm="6" cols="12">
        <b-form-group label="Tutar*" label-for="basicInput">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="coins"/>
            </b-input-group-prepend>
            <b-form-input
                v-model="tutar"
                v-numeric-only
                autocomplete="off"
                :step="priceStep"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col lg="6" md="6" sm="6" cols="12">
        <b-form-group label="Fiş No" label-for="basicInput">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="hashtag"/>
            </b-input-group-prepend>
            <b-form-input
                v-model="fisNo"
                autocomplete="off"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col lg="6" md="6" sm="6" cols="12">
        <b-form-group label="İsim" label-for="basicInput">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="hashtag"/>
            </b-input-group-prepend>
            <b-form-input
                v-model="ad"
                autocomplete="off"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col lg="6" md="6" sm="6" cols="12">
        <b-form-group label="Soy İsim" label-for="basicInput">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="hashtag"/>
            </b-input-group-prepend>
            <b-form-input
                v-model="soyad"
                autocomplete="off"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col lg="12" md="12" sm="12" cols="12">
        <b-form-group label="Departman" label-for="basicInput">
          <model-list-select
              v-model="departmanID"
              :list="departmanList"
              option-value="departmanID"
              option-text="departmanAdi"
              placeholder="Departman Seçiniz"
          />
        </b-form-group>
      </b-col>
      <b-col lg="12" md="12" sm="12" cols="12">
        <b-form-group label="Açıklama" label-for="basicInput">
          <b-form-textarea
              v-model="aciklama"
              rows="3"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="odeme-tip-row mt-lg-0 mt-md-2 mt-sm-2 mt-2 mb-1">
      <b-col lg="4" md="4" sm="6" cols="6" class="p-25">
        <b-card v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :class="odemeTipSelected === 'nakit' ? 'tip-selected':''"
                @click="odemeTipSelected = 'nakit'"
        >
          <div class="odeme-tipi">
            <font-awesome-icon icon="money-bills" class="fa-2xl"/>
            <h5 class="mt-50">Nakit</h5>
          </div>
        </b-card>
      </b-col>
      <b-col lg="4" md="4" sm="6" cols="6" class="p-25">
        <b-card v-ripple.400="'rgba(113, 102, 240, 0.15)'" :class="odemeTipSelected === 'kredi'?'tip-selected':''"
                @click="odemeTipSelected = 'kredi'"
        >
          <div class="odeme-tipi">
            <font-awesome-icon icon="credit-card" class="fa-2xl"/>
            <h5 class="mt-50">Kredi Kartı</h5>
          </div>
        </b-card>
      </b-col>
      <b-col lg="4" md="4" sm="6" cols="6" class="p-25">
        <b-card v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :class="odemeTipSelected === 'havale'?'tip-selected':''"
                @click="odemeTipSelected = 'havale'"
        >
          <div class="odeme-tipi">
            <font-awesome-icon icon="money-bill-transfer" class="fa-2xl"/>
            <h5 class="mt-50">Havale</h5>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="text-right">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="mr-50"
            @click="$emit('finished')"
        >
          Kapat
        </b-button>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            :disabled="!tutar || !departmanID"
            @click="odemeSave()"
        >
          Kaydet
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BBadge,
  BFormTextarea,
  BTabs,
  BTab,
  BButton,
  BRow,
  BCol,
  VBModal,
  BvModal,
  VBTooltip,
  VBToggle,
  BListGroup,
  BListGroupItem,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'
import axios from 'axios'
import CariSave from '@/views/cari/cariSave.vue'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ValidationProvider } from 'vee-validate'

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple,
  },
  components: {
    ModelListSelect,
    ValidationProvider,
    BInputGroupAppend,
    BBadge,
    BFormTextarea,
    CariSave,
    vSelect,
    BInputGroupPrepend,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BCard,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BButton,
    BTabs,
    BTab,
  },
  data() {
    return {
      ad: null,
      soyad: null,
      tutar: null,
      fisNo: null,
      aciklama: null,
      departmanID: null,
      odemeTipSelected: 'nakit',
    }
  },
  created() {
    if (store.getters.GET_DEPARTMAN_LIST.length === 0) {
      this.departmanData()
    }
  },
  computed: {
    departmanList: () => store.getters.GET_DEPARTMAN_LIST,
    tahsilatTuru() {
      const tip = this.odemeTipSelected
      return tip === 'nakit' ? 1 : tip === 'havale' ? 2 : tip === 'kredi' ? 3 : 1
    },
  },
  methods: {
    departmanData() {
      const fd = this.postSchema()
      fd.set('serviceName', 'restoran')
      fd.append('methodName', 'departmanListesi')
      axios.post('', fd)
          .then(response => {
            if (response?.result?.status === 200) {
              store.commit({
                type: 'SET_DEPARTMAN_LIST',
                action: 1,
                list: response.departmanList,
              })
            }
          })
    },
    odemeSave() {
      const fd = this.postSchema()
      fd.append('methodName', 'gelirKayit')
      fd.append('gelir', String(this.tutar || 0)
          .replace(/,/g, '.'))
      fd.append('odemeTuru', this.tahsilatTuru || 1)
      fd.append('aciklama', this.aciklama || '')
      fd.append('fisNo', this.fisNo || '')
      fd.append('ad', this.ad || '')
      fd.append('soyad', this.soyad || '')
      fd.append('departmanID', this.departmanID || '')
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.$emit('finished')
              if (this.$route.name === 'vardiyaDetayi') {
                const fdd = this.postSchema()
                fdd.append('methodName', 'vardiyaOzeti')
                axios.post('', fdd)
                    .then(vardiyaResponse => {
                      if (vardiyaResponse.result.status === 200) {
                        store.commit({
                          type: 'SET_VARDIYA_DETAY',
                          action: 1,
                          list: vardiyaResponse.vardiyaData,
                        })
                      }
                    })
              }
              this.modalReset()
            }
          })
    },
    modalReset() {
      this.ad = null
      this.soyad = null
      this.tutar = null
      this.fisNo = null
      this.aciklama = null
      this.departmanID = null
      this.odemeTipSelected = 'nakit'
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
      fd.append('serviceName', 'vardiya')
      return fd
    },
  },
}
</script>

<style scoped>

.odeme-tipi {
  display: grid;
  place-items: center;
}

.tip-selected {
  border: 2px solid #4CACBC;
}

.odeme-tip-row .card {
  margin: 0;
}

.odeme-tipi .card-body {
  padding: 0.8rem;
}

.odeme-tip-row .card-body {
  padding: 20px 0 5px 0;
}

</style>
