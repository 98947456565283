<template>
  <b-row>
    <validation-observer ref="firstForm">
      <b-form class="d-flex">
        <b-row>
          <b-col xl="8" lg="12" md="12" sm="12" cols="12">
            <h4 class="text-warning font-weight-bolder mb-1">Cari Bilgileri</h4>
            <b-row>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group label="Adı*" label-for="basicInput">
                  <validation-provider
                      #default="{ errors }"
                      name="adi"
                      rules="required"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text :class="errors.length > 0 ? 'input-error':''">
                        <font-awesome-icon icon="user"/>
                      </b-input-group-prepend>
                      <b-form-input
                          v-model="adi"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] ? 'Ad zorunludur' : '' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group label="Soyadı*" label-for="basicInput">
                  <validation-provider
                      #default="{ errors }"
                      name="Soyadi"
                      rules="required"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text :class="errors.length > 0 ? 'input-error':''">
                        <font-awesome-icon icon="user"/>
                      </b-input-group-prepend>
                      <b-form-input
                          v-model="soyAdi"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] ? 'Soyadı zorunludur' : '' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="Adres"
                    label-for="basicInput"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <font-awesome-icon icon="location-dot"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="basicInput"
                        v-model="adres"
                        autocomplete="off"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="E-Posta"
                    label-for="basicInput"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <font-awesome-icon icon="envelope"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="basicInput"
                        v-model="ePosta"
                        autocomplete="off"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="GSM"
                    label-for="basicInput"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <font-awesome-icon icon="hashtag"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="basicInput"
                        v-model="gsm"
                        autocomplete="off"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="6" sm="12" cols="12">
                <b-form-group
                    label="Telefon"
                    label-for="basicInput"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <font-awesome-icon icon="phone"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="basicInput"
                        v-model="telefon"
                        autocomplete="off"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col xl="4" lg="12" md="12" sm="12" cols="12">
            <h4 class="text-success font-weight-bolder mb-1">Fatura Bilgileri</h4>
            <b-row>
              <b-col cols="12">
                <b-form-group
                    label="Firma Unvanı"
                    label-for="basicInput"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <font-awesome-icon icon="paperclip"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="basicInput"
                        v-model="firmaUnvan"
                        autocomplete="off"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Vergi No / Tc No*">
                  <validation-provider
                      #default="{ errors }"
                      name="TcNo"
                      rules="required|min:10"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text :class="errors.length > 0 ? 'input-error':''">
                        <font-awesome-icon icon="user"/>
                      </b-input-group-prepend>
                      <b-form-input
                          v-model="vergiNumarasi"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                          type="number"
                      />
                    </b-input-group>
                    <small class="text-danger" v-if="tcControl">
                      {{ errors[0] ? 'Vergi No veya Tc No 10 ila 11 karakter arasında olmalıdır.' : '' }}
                    </small>
                    <small class="text-danger" v-if="!tcControl">
                      Vergi No veya Tc No 10 ila 11 karakter arasında olmalıdır.
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="Vergi Dairesi"
                    label-for="basicInput"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <font-awesome-icon icon="building"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="basicInput"
                        v-model="vergiDairesi"
                        autocomplete="off"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <hr/>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="relief-success"
                    type="submit"
                    @click.prevent="validationForm()"
                >
                  <font-awesome-icon icon="floppy-disk" class="mr-50"/>
                  Kaydet
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-row>
</template>

<script>
import {
  BForm,
  VBModal,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  VBTooltip,
  BInputGroupPrepend, BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import 'vue-search-select/dist/VueSearchSelect.css'
import store from '@/store'
import axios from 'axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BInputGroupPrepend,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      adi: null,
      soyAdi: null,
      adres: null,
      ePosta: null,
      gsm: null,
      telefon: null,
      firmaUnvan: null,
      vergiDairesi: null,
      vergiNumarasi: null,
      tcControl: true,
    }
  },
  computed: {},
  watch: {
    vergiNumarasi(newVal) {
      if (newVal?.length <= 11) {
        this.tcControl = true
      } else {
        this.tcControl = false
      }
    },
  },
  methods: {
    validationForm() {
      this.$refs.firstForm.validate()
          .then(success => {
            if (success) {
              if (this.vergiNumarasi?.length <= 11) {
                this.tcControl = true
                this.saveCari()
              } else {
                this.tcControl = false
              }
            }
          })
    },
    saveCari() {
      const fd = this.postSchema()
      fd.append('methodName', 'cariKayit')
      fd.append('saveData[adi]', this.adi || ' ')
      fd.append('saveData[soyAdi]', this.soyAdi || ' ')
      fd.append('saveData[adres]', this.adres || ' ')
      fd.append('saveData[ePosta]', this.ePosta || ' ')
      fd.append('saveData[gsm]', this.gsm || ' ')
      fd.append('saveData[telefon]', this.telefon || ' ')
      fd.append('saveData[faturaUnvan]', this.firmaUnvan || ' ')
      fd.append('saveData[vergiDairesi]', this.vergiDairesi || ' ')
      fd.append('saveData[vergiNumarasi]', this.vergiNumarasi || ' ')
      axios.post('', fd)
          .then(response => this.$emit('saving', 1, response.cariData))
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
      fd.append('serviceName', 'cari')
      return fd
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
  },
}
</script>
<style>
</style>
