export default [
  {
    header: 'Sipariş',
    title: 'Sipariş',
    route: 'dashBoard',
    icon: 'bag-shopping',
  },
  {
    header: 'Mutfak',
    title: 'Mutfak',
    route: 'kitchen',
    icon: 'glass-water-droplet',
  },
  {
    header: 'Cari',
    icon: 'users',
    children: [
      {
        title: 'Cari Kartlar',
        route: 'cariListe',
        icon: 'table-cells',
      },
    ],
  },
  {
    header: 'Tanımlamalar',
    icon: 'gear',
    children: [
      {
        title: 'Masa / Salon',
        route: 'salon&masa',
        icon: 'table-cells',
      },
      {
        title: 'Menü',
        route: 'menu',
        icon: 'utensils',
      },
    ],
  },
]
