<template>
  <b-nav-item-dropdown
      class="dropdown-cart mr-25"
      menu-class="dropdown-menu-media"
      right
  >
    <template #button-content>
      <feather-icon
          badge="2"
          class="text-body"
          icon="ShoppingCartIcon"
          size="21"
      />
    </template>
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          My Cart
        </h4>
        <b-badge
            pill
            variant="light-primary"
        >
          2
        </b-badge>
      </div>
    </li>
    <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
    >
      <b-media>
        <template #aside>
          <b-img
              src=""
              alt=""
              rounded
              width="62px"
          />
        </template>
        <feather-icon
            icon="XIcon"
            class="cart-item-remove cursor-pointer"
            @click.stop
        />
        <div class="media-heading">
          <h6 class="cart-item-title">
            <b-link class="text-body">
              selamlar
            </b-link>
          </h6>
          <small class="cart-item-by">Marka</small>
        </div>
        <div class="cart-item-qty ml-1">
          <b-form-spinbutton
              min="1"
              size="sm"
          />
        </div>
        <h5 class="cart-item-price">
          fiyat
        </h5>
      </b-media>
    </vue-perfect-scrollbar>
    <li
        v-if="items.length"
        class="dropdown-menu-footer"
    >
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">
          Total:
        </h6>
        <h6 class="text-primary font-weight-bolder mb-0">
          ${{ totalAmount }}
        </h6>
      </div>
      <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
          :to="{ name: 'apps-e-commerce-checkout' }"
      >
        Checkout
      </b-button>
    </li>
    <p
        v-if="!items.length"
        class="m-0 p-1 text-center"
    >
      Your cart is empty
    </p>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BImg, BFormSpinbutton, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BImg,
    BFormSpinbutton,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    totalAmount() {
      let total = 0
      this.items.forEach(i => {
        total += i.price
      })
      return total
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
