<template>
  <li
      :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
        v-bind="linkProps"
        class="dropdown-item"
    >
      <font-awesome-icon :icon="item.icon"/>
      <span class="menu-title">{{ t(item.title) }}</span>
    </b-link>
  </li>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'
import useHorizontalNavMenuLink from './useHorizontalNavMenuLink'
import mixinHorizontalNavMenuLink from './mixinHorizontalNavMenuLink'

export default {
  components: {
    BLink,
  },
  mixins: [mixinHorizontalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      isActive,
      linkProps,
      updateIsActive,
    } = useHorizontalNavMenuLink(props.item)

    const { t } = useI18nUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,
      t,
    }
  },

}
</script>
