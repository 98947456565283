<template>
  <li
      v-if="roleControl()"
      class="nav-item"
      :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
      @click="actions(item)"
  >
    <b-link
        v-bind="linkProps"
        class="d-flex align-items-center"
    >
      <font-awesome-icon :icon="item.icon || 'circle'"/>
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'
import axios from 'axios'
import store from '@/store'
import router from '@/router'

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      isActive,
      linkProps,
      updateIsActive
    } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },
  methods: {
    actions(item) {
      if (item.title === 'Gelir Kaydı') {
        this.$bvModal.show('gelir-kaydi')
      }
      if (item.title === 'Gider Kaydı') {
        this.$bvModal.show('gider-kaydi')
      }
      if (item.title === 'Vardiya Kapat') {
        this.$swal({
          title: 'Eminmisiniz ? ',
          text: 'Vardiya Kapanacaktır.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Evet Kapat',
          cancelButtonText: 'Vazgeç',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(result => {
              if (result.value) {
                const fd = new FormData()
                fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
                fd.append('serviceName', 'vardiya')
                fd.append('methodName', 'vardiyaKapat')
                axios.post('', fd)
                    .then(response => {
                      if (response.result.status === 204) {
                        store.commit({
                          type: 'SET_DASH_DATA',
                          action: 0,
                        })
                        this.$swal({
                          icon: 'success',
                          title: 'Başarılı',
                          text: 'Vardiya Kapandı.',
                          customClass: {
                            confirmButton: 'btn btn-success',
                          },
                        })
                        store.state.isVardiya = false
                        router.push('/kapaliVardiya')
                      }
                    })
              }
            })
      }
    },
    roleControl() {
      const { role } = this.$store.state
      const { title } = this.item
      if (role === 'pc') {
        return title !== 'Mutfak'
      }
      if (role === 'tablet') {
        return title === 'Dashboard'
      }
      if (role === 'mutfak') {
        return title === 'Mutfak'
      }
    },
  },

}
</script>
