<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
            class="navbar-brand"
            to="/"
            @click="dashBoardData()"
        >
          <span class="brand-logo">
            <b-img
                :src="appLogoImage"
                alt="logo"
            />
          </span>
          <h2 class="brand-text mb-0">
            {{ appName }}
          </h2>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import axios from 'axios'
import store from '@/store'

export default {
  components: {
    BLink,
    BImg,
  },
  setup() {
    // App Name
    const {
      appName,
      appLogoImage
    } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  methods: {
    dashBoardData() {
      const fd = this.postSchema()
      axios.post('', fd)
          .then(response => store.commit({
            type: 'SET_DASH_DATA',
            action: 1,
            list: response.dashData,
          }))
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
      fd.append('serviceName', 'dashBoard')
      return fd
    },
  },
}
</script>

<style>

</style>
