<template>
  <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex user-nav">
        <p class="user-name font-weight-bolder mb-0 text-white">
          {{ info.userName }}
        </p>
        <span class="text-white user-status">{{ info.role }}</span>
      </div>
      <b-avatar
          size="40"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
      >
        <feather-icon
            icon="UserIcon"
            size="22"
        />
      </b-avatar>
    </template>
    <b-dropdown-item
        v-b-modal.effect
        link-class="d-flex align-items-center"
    >
      <feather-icon
          size="16"
          icon="MenuIcon"
          class="mr-50"
      />
      <span>Geçiş Efektleri</span>
    </b-dropdown-item>
    <b-dropdown-item
        v-b-modal.password-update
        link-class="d-flex align-items-center"
    >
      <feather-icon
          size="16"
          icon="LockIcon"
          class="mr-50"
      />
      <span>Şifre Güncelle</span>
    </b-dropdown-item>
    <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
    >
      <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item>
    <b-modal
        id="password-update"
        ok-only
        ok-variant="success"
        ok-title="Kaydet"
        modal-class="modal-success"
        centered
        title="Şifre Güncelle"
        :ok-disabled="!password"
        @ok="passwordUpdate"
    >
      <b-form-group
          label="Yeni Şifre"
          label-for="basicInput"
      >
        <b-form-input
            id="basicInput"
            v-model="password"
            autocomplete="off"
        />
      </b-form-group>
    </b-modal>
    <b-modal
        id="effect"
        ok-only
        ok-variant="success"
        ok-title="Kaydet"
        modal-class="modal-success"
        centered
        no-fade
        title="Sayfa Geçiş Efektleri"
        @ok="effectSave()"
    >
      <b-form-select
          v-model="selected"
          :options="options"
      />
    </b-modal>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BModal, BButton, BFormInput, BFormGroup, BFormSelect
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    BFormSelect,
    BFormInput,
    BFormGroup,
    BButton,
    BModal,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      selected: null,
      options: [
        {
          value: 'slide-fade',
          text: 'Slide Fade',
        },
        {
          value: 'zoom-fade',
          text: 'Zoom Fade',
        },
        {
          value: 'fade-bottom',
          text: 'Fade Bottom',
        },
        {
          value: 'fade',
          text: 'Fade',
        },
        {
          value: 'zoom-out',
          text: 'Zoom Out',
        },
        {
          value: 'none',
          text: 'Yok',
        },
      ],
      password: null,
      userData: JSON.parse(localStorage.getItem('restoranUserData')),
      avatarText,
    }
  },
  computed: {
    info() {
      return {
        role: store.state.role,
        userName: localStorage.getItem('restoranFullName'),
      }
    },
  },
  created() {
    const {
      routerTransition,
    } = useAppConfig()
    this.selected = routerTransition.value
  },
  methods: {
    effectSave() {
      const {
        routerTransition,
      } = useAppConfig()
      routerTransition.value = this.selected
      localStorage.setItem('restoranPageEffect', this.selected)
    },
    passwordUpdate() {
    },
    logout() {
      store.state.isAuth = false
      store.commit('removeToken')
      store.commit('removeUserData')
      localStorage.removeItem('restoranUserData')
      localStorage.removeItem('restoranFirmaKodu')
      localStorage.removeItem('restoranUserName')
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      this.$ability.update(initialAbility)
      this.$router.push({ name: 'login' })
    },
  },
}
</script>

<style>
.b-avatar-custom svg.feather {
  color: white;
}
</style>
