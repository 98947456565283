<template>
  <b-modal
      id="gider-kaydi"
      centered
      modal-class="modal-primary"
      no-fade
      hide-footer
      title="Gider Kaydı"
  >
    <b-row>
      <b-col lg="6" md="6" sm="6" cols="12">
        <b-form-group label="Tutar*" label-for="basicInput">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="coins"/>
            </b-input-group-prepend>
            <b-form-input
                v-model="tutar"
                v-numeric-only
                autocomplete="off"
                :step="priceStep"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col lg="6" md="6" sm="6" cols="12">
        <b-form-group label="Fiş No" label-for="basicInput">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="hashtag"/>
            </b-input-group-prepend>
            <b-form-input
                v-model="fisNo"
                autocomplete="off"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col lg="12" md="12" sm="12" cols="12">
        <b-form-group label="Açıklama" label-for="basicInput">
          <b-form-textarea
              v-model="aciklama"
              rows="3"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="text-right">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="mr-50"
            @click="$emit('finished')"
        >
          Kapat
        </b-button>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            :disabled="!tutar"
            @click="odemeSave()"
        >
          Kaydet
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BBadge,
  BFormTextarea,
  BTabs,
  BTab,
  BButton,
  BRow,
  BCol,
  VBModal,
  BvModal,
  VBTooltip,
  VBToggle,
  BListGroup,
  BListGroupItem,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'
import axios from 'axios'
import CariSave from '@/views/cari/cariSave.vue'
import router from '@/router'
import { ValidationProvider } from 'vee-validate'

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple,
  },
  components: {
    ValidationProvider,
    BInputGroupAppend,
    BBadge,
    BFormTextarea,
    CariSave,
    vSelect,
    BInputGroupPrepend,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BCard,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BButton,
    BTabs,
    BTab,
  },
  data() {
    return {
      tutar: null,
      fisNo: null,
      aciklama: null,
    }
  },
  created() {
    this.$bvModal.show('gider-kaydi')
  },
  methods: {
    btnDisabled() {
      return !this.tutar
    },
    odemeSave() {
      const fd = this.postSchema()
      fd.append('methodName', 'giderKayit')
      fd.append('tutar', String(this.tutar || 0)
          .replace(/,/g, '.'))
      fd.append('aciklama', this.aciklama || '')
      fd.append('fisNo', this.fisNo || '')
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.$emit('finished')
              if (this.$route.name === 'vardiyaDetayi') {
                const fdd = this.postSchema()
                fdd.append('methodName', 'vardiyaOzeti')
                axios.post('', fdd)
                    .then(vardiyaResponse => {
                      if (vardiyaResponse.result.status === 200) {
                        store.commit({
                          type: 'SET_VARDIYA_DETAY',
                          action: 1,
                          list: vardiyaResponse.vardiyaData,
                        })
                      }
                    })
                const giderFd = this.postSchema()
                giderFd.set('methodName', 'giderListesi')
                axios.post('', giderFd)
                    .then(giderResponse => {
                      if (giderResponse.result.status === 200) {
                        store.commit({
                          type: 'SET_GIDER_LIST',
                          action: 1,
                          list: giderResponse.giderList,
                        })
                      }
                    })
              }
              this.modalReset()
            }
          })
    },
    modalReset() {
      this.tutar = null
      this.fisNo = null
      this.aciklama = null
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
      fd.append('serviceName', 'vardiya')
      return fd
    },
  },
}
</script>

<style scoped>

</style>
