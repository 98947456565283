<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25 text-white">
      <span class="d-none d-sm-inline-block">Öğretmenevi Yönetim Sistemi (Restoran Modül) © {{
          new Date().getFullYear()
        }} 3K-CRM Mimarisi üzerine dizayn edilmiştir. Tüm Hakları Saklıdır.</span>
    </span>

    <span class="float-md-right d-none d-md-block">
      <b-link
          class="ml-25 text-white"
          href="https://www.arvas.com.tr/"
          target="_blank"
      >Arvas Bilgi Teknolojileri Version:1.0-Beta</b-link>
      <feather-icon
          icon="HeartIcon"
          size="21"
          class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
