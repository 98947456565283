<template>
  <b-navbar-nav class="nav">
    <b-nav-item to="/">
      <b-img
          class="logo-css"
          :src="oysLogo"
          fluid
          alt="Oys Panel"
      />
    </b-nav-item>
    <b-nav-item v-if="$store.state.role === 'pc'">
      <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-primary"
          to="/cariListe"
          class="text-white"
      >
        Cari Kartlar
      </b-button>
    </b-nav-item>
    <b-nav-item v-if="$store.state.role === 'pc'">
      <b-dropdown
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          text="İşlemler"
          variant="flat-primary"
      >
        <b-dropdown-item v-if="$store.state.role === 'pc'" v-b-modal.gelir-kaydi variant="success">
          <font-awesome-icon icon="plus" class="mr-50"/>
          Gelir Kaydı
        </b-dropdown-item>
        <b-dropdown-item v-if="$store.state.role === 'pc'" v-b-modal.gider-kaydi variant="danger"
        >
          <font-awesome-icon icon="minus" class="mr-50"/>
          Gider Kaydı
        </b-dropdown-item>
        <b-dropdown-item @click="vardiyaClosed()">
          <font-awesome-icon icon="circle-stop" class="mr-50"/>
          Vardiya Kapat
        </b-dropdown-item>
      </b-dropdown>
    </b-nav-item>
    <b-nav-item v-if="$store.state.role === 'pc'">
      <b-dropdown
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          text="Raporlar"
          variant="flat-primary"
      >
        <b-dropdown-item to="/vardiyaDetayi/0">
          <font-awesome-icon icon="chart-line" class="mr-50"/>
          Vardiya Detayı
        </b-dropdown-item>
        <b-dropdown-item to="/vardiyaListesi">
          <font-awesome-icon icon="bars" class="mr-50"/>
          Vardiya Listesi
        </b-dropdown-item>
        <b-dropdown-item to="/kasaRaporu">
          <font-awesome-icon icon="box-archive" class="mr-50"/>
          Kasa Raporu
        </b-dropdown-item>
      </b-dropdown>
    </b-nav-item>
    <b-nav-item v-if="$store.state.role === 'pc'">
      <b-dropdown
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          text="Tanımlamalar"
          variant="flat-white"
      >
        <b-dropdown-item to="/salon&masa">
          <font-awesome-icon icon="list" class="mr-50"/>
          Masa / Salon
        </b-dropdown-item>
        <b-dropdown-item to="/menu">
          <font-awesome-icon icon="utensils" class="mr-50"/>
          Menü
        </b-dropdown-item>
      </b-dropdown>
    </b-nav-item>
    <b-nav-item v-if="$store.state.role === 'pc'">
      <b-dropdown
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          text="Ayarlar"
          variant="flat-primary"
      >
        <b-dropdown-item to="/kullanicilar">
          <font-awesome-icon icon="users" class="mr-50"/>
          Kullanıcı Yönetimi
        </b-dropdown-item>
        <b-dropdown-item to="/departman">
          <font-awesome-icon icon="database" class="mr-50"/>
          Departman Yönetimi
        </b-dropdown-item>
      </b-dropdown>
    </b-nav-item>
    <b-nav-item v-if="$store.state.role === 'mutfak'">
      <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-primary"
          to="/mutfak"
          class="text-white"
      >
        Mutfak
      </b-button>
    </b-nav-item>
    <gelir-kaydi @finished="paymentFinished(1)"/>
    <gider-kaydi @finished="paymentFinished(0)"/>
  </b-navbar-nav>
</template>

<script>
import {
  BNavbarNav, BNavItem, BTooltip, BNavItemDropdown, BFormInput, BDropdownItem, BDropdown, VBTooltip, BButton, BImg
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useAutoSuggest from '@core/components/app-auto-suggest/useAutoSuggest'
import { ref, watch } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import store from '@/store'
import searchAndBookmarkData from '../search-and-bookmark-data'
import axios from 'axios'
import GelirKaydi from '@/views/financeManager/gelirKaydi.vue'
import GiderKaydi from '@/views/financeManager/giderKaydi.vue'

export default {
  components: {
    GiderKaydi,
    GelirKaydi,
    BImg,
    BButton,
    BNavbarNav,
    BNavItem,
    BTooltip,
    BNavItemDropdown,
    BFormInput,
    VuePerfectScrollbar,
    BDropdownItem,
    BDropdown,
  },
  directives: {
    Ripple,
  },
  setup() {
    const searchAndBookmarkDataPages = ref(searchAndBookmarkData.pages)
    const bookmarks = ref(searchAndBookmarkData.pages.data.filter(page => page.isBookmarked))
    const currentSelected = ref(-1)
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }
    const {
      searchQuery,
      resetsearchQuery,
      filteredData,
    } = useAutoSuggest({
      data: { pages: searchAndBookmarkDataPages.value },
      searchLimit: 6
    })
    watch(searchQuery, val => {
      store.commit('app/TOGGLE_OVERLAY', Boolean(val))
    })
    watch(filteredData, val => {
      currentSelected.value = val.pages && !val.pages.length ? -1 : 0
    })
    const suggestionSelected = () => {
      const suggestion = filteredData.value.pages[currentSelected.value]
      router.push(suggestion.route)
          .catch(() => {
          })
      resetsearchQuery()
    }
    const toggleBookmarked = item => {
      // Find Index of item/page in bookmarks' array
      const pageIndexInBookmarks = bookmarks.value.findIndex(i => i.route === item.route)
      // If index is > -1 => Item is bookmarked => Remove item from bookmarks array using index
      // Else => Item is not bookmarked => Add item to bookmarks' array
      if (pageIndexInBookmarks > -1) {
        bookmarks.value[pageIndexInBookmarks].isBookmarked = false
        bookmarks.value.splice(pageIndexInBookmarks, 1)
      } else {
        bookmarks.value.push(item)
        bookmarks.value[bookmarks.value.length - 1].isBookmarked = true
      }
    }
    return {
      oysLogo: require('@/assets/images/restoran/logo/logo.png'),
      bookmarks,
      perfectScrollbarSettings,
      currentSelected,
      suggestionSelected,
      toggleBookmarked,

      // AutoSuggest
      searchQuery,
      resetsearchQuery,
      filteredData,
    }
  },
  data() {
    return {}
  },
  methods: {
    paymentFinished(modal) {
      if (modal === 1) {
        this.$bvModal.hide('gelir-kaydi')
      } else {
        this.$bvModal.hide('gider-kaydi')
      }
    },
    vardiyaClosed() {
      this.$swal({
        title: 'Eminmisiniz ? ',
        text: 'Vardiya Kapanacaktır.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet Kapat',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              const fd = this.postSchema()
              fd.set('serviceName', 'vardiya')
              fd.append('methodName', 'vardiyaKapat')
              axios.post('', fd)
                  .then(response => {
                    if (response.result.status === 204) {
                      store.commit({
                        type: 'SET_DASH_DATA',
                        action: 0,
                      })
                      this.$swal({
                        icon: 'success',
                        title: 'Başarılı',
                        text: 'Vardiya Kapandı.',
                        customClass: {
                          confirmButton: 'btn btn-success',
                        },
                      })
                      store.state.isVardiya = false
                      router.push('/kapaliVardiya')
                    }
                  })
            }
          })
    },
    dashBoardData() {
      const fd = this.postSchema()
      axios.post('', fd)
          .then(response => {
            store.commit({
              type: 'SET_DASH_DATA',
              action: 1,
              list: response.dashData,
            })
          })
          .finally(() => {
            if (this.$route.name !== 'dashBoard') {
              router.push('/')
            }
          })
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
      fd.append('serviceName', 'dashBoard')
      return fd
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
}

.nav-bookmar-content-overlay {
  position: fixed;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  z-index: -1;

  &:not(.show) {
    pointer-events: none;
  }

  &.show {
    cursor: pointer;
    z-index: 10;
    opacity: 1;
  }
}

.logo-css {
  height: 40px !important;
}

.nav-link {
  padding: 0 !important;
}
</style>
<style>
.nav-link .dropdown .btn {
  color: white !important;
}
</style>
